import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import he from 'he'
import Parser from 'html-react-parser'

import SEO from '../components/seo'
import Newsletter from '../components/newsletter'
import Search from '../assets/images/search.svg'
import NewsletterBg from '../assets/images/newsletter-bg.jpg'

class PostTemplate extends Component {
  render() {
    let article = this.props.data.wordpressPost
    let metaDescription = article.yoast_meta.find(x => x.name === 'description') ? article.yoast_meta.find(x => x.name === 'description').content : '';
    let media = this.props.data.allWordpressWpMedia.edges
    let latest = this.props.data.posts.edges.slice(0, 3)

    const Content = Parser(article.content, {
      replace: domNode => {
        if (domNode.name === 'img') {
          // TODO: also check src attribute for 'wp-content/uploads'
          const width = domNode.attribs.width
          const height = domNode.attribs.height
          const replaceString = '-'+width+'x'+height
          const imageString = domNode.attribs.src.replace(replaceString, '')
          let image = media.filter(m => {
            return m.node.source_url === imageString
          })
          if (image) {
            return <img src={image[0]?.node?.localFile?.childImageSharp?.original?.src} alt='FSC Group' />
          }
        }
      },
    })
    return (
      <>
        <SEO title={he.decode(article.yoast_title)} bodyClass={'single-post'} description={metaDescription} />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/news'>News</Link></li>
              <li>{he.decode(article.title)}</li>
            </ul>
          </div>
        </section>
        <section className="news">
          <div className="news__inner container">
            <div className='news__sidebar'>
              <h1>News</h1>
              <div className='news__description'>
                <p>Latest news and insights from the FSC Group.</p>
              </div>
              <form className='news__search' action='/news'>
                <h4>Search</h4>
                <input type='text' name='s' placeholder='' />
                <button>
                  <img src={Search} alt='Search' />
                </button> 
              </form>
              <div className='news__popular'>
                <h4>Related Articles</h4>
                <ul>
                  { latest.map((el, i) => (
                    <li key={i} {...el.node}><Link to={`/news/${el.node.slug}`}>{el.node.title}</Link></li>
                  )) }
                </ul>
              </div>
            </div>
            <div className='news__items'>
              <img className="basic__thumbnail" alt={article.title} src={article.acf.featured_image && article.acf.featured_image.localFile && article.acf.featured_image.localFile.childImageSharp && article.acf.featured_image.localFile.childImageSharp.original.src} />
              <h1>{he.decode(article.title)}</h1>
              { article.acf.authors && article.acf.authors.length &&
                <div className='blog__authors'>
                  { article.acf.authors.map((el, i) => (
                    <div className='blog__author' key={i}>
                      <img src={el.image?.localFile?.childImageSharp?.original.src} alt={el.name} />
                      <div>
                        <p>{el.name}</p>
                        <span>{el.position}</span>
                      </div>
                    </div>
                  ))}
                </div>
              }
              <div className='blog__content'>
                {Content}
              </div>
              <div className='blog__tags'>
                <h4>Tagged Under</h4>
                <ul>
                  { article.categories.length > 0 && article.categories.map((el, i) => (
                    <li key={i}>
                      <button className='btn'>
                        {el.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='blog__subscribe'>
                <h4>Subscribe to our mailing list</h4>
                <p>Be alerted when new articles and insights are published.</p>
                <Newsletter />
                <img src={NewsletterBg} alt='Subscribe' />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export const postQuery = graphql`
  query ($id: String!) {
    wordpressPost(id: {eq: $id}) {
      title
      slug
      date
      content
      yoast_title
      yoast_meta {  
       name
       content
       property
      }
      categories {
        slug
        name
      }
      acf {
        featured_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        authors {
          image {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
          name
          position
        }
      }
    }
    allWordpressWpMedia {
      edges {
        node {
          id
          source_url
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
    posts: allWordpressPost(filter: {id: {ne: $id}}) {
      edges {
        node {
          title
          content
          slug
          categories {
            name
            slug
          }
          acf {
            excerpt
            featured_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PostTemplate