import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class NewsletterForm extends Component {

  state = {
    firstname: null,
    lastname: null,
    email: '',
    result: {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // 2. via `async/await`
  handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email, {
      FNAME: this.state.firstname,
      LNAME: this.state.lastname,
    })
    // I recommend setting `result` to React state
    // but you can do whatever you want
    this.setState({
      result: result.result,
      msg: result.msg
    })
  }

  render() {
    let props = {
      ref: "form",
      name: "newsletter",
      className: "newsletter",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    if (this.state.result === "success")
      return (
        <p className="contact__success">
          Thanks for subscribing.
        </p>
      )

    return (
      <form {...props}>
        <div className="newsletter__row">
          <input
            type="email"
            name="email"
            placeholder="Your email address"
            onChange={this.handleChange}
            required
          />
          <button type="submit" className="btn"></button>
        </div>
      </form>
    )
  }
}

export default NewsletterForm